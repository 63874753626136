:root {
    --light-gray: #999;
    --gray: #666;
    --dark-gray: #333;

    --color-secondary:#7030a0;;
    --color-primary: #e60000;

    --button-primary: #e60000;
    --button-secondary: #7030a0;
}