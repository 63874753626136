.ms-List-cell .bgWhiteRow .ms-DetailsRow {
  background-color: white !important;
}

.ms-List-cell .bgRedRow .ms-DetailsRow {
  background-color: #fbd3d3 !important;
}

.ms-List-cell .bgOrangeRow .ms-DetailsRow {
  background-color: #ffdab9 !important;
}

.ms-List-cell:nth-child(even) .ms-DetailsRow {
  background: #fbd3d3;
}
