@import url("site.css");

.ee.container .title {
    font-weight: bold;
    color: var(--color-primary);
}

.ee.container .level {
    font-weight:bold;   
}

.ef.container .action button {
    margin-right: 8px;
    margin-bottom: 8px;
}

.ee.container .detail {
    color: var(--color-secondary);
}

.user-item {}

.user-item .name {
    font-weight: bold;
    color: var(--color-secondary);
}

.product {
    color: var(--color-secondary);
}

.ms-Nav-navItem a {
    color: var(--color-primary) !important;
}

.ms-Nav-navItem a:hover {
    text-decoration: none !important;
}