@font-face {
    font-family: 'Vodafone';
    src: url("/css/fonts/VodafoneRg.eot");
    src: url("/css/fonts/VodafoneRg.woff2") format("woff2"), url("/css/fonts/VodafoneRg.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Vodafone';
    src: url("/css/fonts/vodafonergbd-webfont.eot");
    src: url("/css/fonts/vodafonergbd-webfont.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Vodafone';
    src: url("/css/fonts/VodafoneLt.eot");
    src: url("/css/fonts/VodafoneLt.woff") format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "Vodafone";
    src: url("/css/fonts/VodafoneExBd.eot");
    src: url("/css/fonts/VodafoneExBd.eot?#iefix") format("embedded-opentype"), url("/css/fonts/VodafoneExBd.woff2") format("woff2"), url("/css/fonts/VodafoneExBd.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap
}

body,
div,
a,
button,
label,
p {
    font-family: Vodafone !important;
    /*, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;*/
}

body,
html,
div,
a,
button {
    outline: none !important;
}

.ms-Panel-headerText,
h1 {
    font-size: 22px;
    font-family: Vodafone !important;
    color: #e60000;
    font-weight: 400;
}

h2 {
    font-size: 18px;
    font-family: Vodafone !important;
    color: #e60000;
    font-weight: 400;
}

h3 {
    font-size: 16px;
    font-family: Vodafone !important;
    color: #e60000;
    font-weight: 400;
}

.fr {
    float: right;
}

.tar {
    text-align: right;
}

.tal {
    text-align: left;
}

.np {
    padding: 0;
}

.npl {
    padding-left: 0;
}

.npr {
    padding-right: 0;
}

.ef .row {
    margin-bottom: 8px;
}

а:hover {
    text-decoration: none !important;
}

div[role=gridcell] {
    padding-bottom: 0px !important;
}