.dcicon {
  display: inline-block;
  font-family: FabricMDL2Icons;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  margin-right: 5px;
  padding: 5px;
  color: #E60000; }

.dcicon:hover {
  background: #eaeaea; }
