.header {
  height: 50px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 999; }

.header .bg {
  background: #333;
  height: 50px; }

.header .logo {
  margin-left: 60px;
  margin-top: 5px;
  float: left; }

.header .user {
  position: absolute;
  right: 10px;
  top: 5px;
  color: #fff;
  }

  .primaryText-62:hover{
     color: #fff;
  }

  .primaryText-62{
    color: #fff ;
  }

.header .user .picture {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #fff;
  border: 1px solid #696;
  float: left;
  background-size: contain; }

.header .user .name {
  float: left;
  color: #fff;
  margin: 10px; }

#container {
  padding: 5px; }
