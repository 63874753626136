.loader-vf {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1000;
    width:100%;
    z-index:1000001;
    background: rgba(0, 0, 0, 0.5);
}

.loader-vf .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.loader-vf .wrapper .inner {
    background: #fff;
    width:100%;
    padding: 16px;
}

.bGVQBS{
    position:relative;
    display:-webkit-inline-box;
    display:-webkit-inline-flex;
    display:-ms-inline-flexbox;
    display:inline-flex;
    vertical-align:middle;
}
.bGVQBS span{
    display:-webkit-inline-box;
    display:-webkit-inline-flex;
    display:-ms-inline-flexbox;
    display:inline-flex;
}
.bGVQBS svg{
    width:16px;
    height:16px;
    stroke:#E60000;
    stroke:#FFFFFF;
}
@media (min-width:768px){
    .bGVQBS svg{
        width:16px;
        height:16px;
    }
}
@media (min-width:1025px){
    .bGVQBS svg{
        width:16px;
        height:16px;
    }
}
@media (min-width:1441px){
    .bGVQBS svg{
        width:16px;
        height:16px;
    }
}
.iDuqAg .animation-container-enter{
    -webkit-animation:slideFromTop 0.3s 0s,fadeIn 0.3s 0s;
    animation:slideFromTop 0.3s 0s,fadeIn 0.3s 0s;
    -webkit-animation-fill-mode:both;
    animation-fill-mode:both;
}
@-webkit-keyframes slideFromTop{
    0%{
        -webkit-transform:translateY(-100%);
        -ms-transform:translateY(-100%);
        transform:translateY(-100%);
    }
    100%{
        -webkit-transform:translateY(0);
        -ms-transform:translateY(0);
        transform:translateY(0);
    }
}
@keyframes slideFromTop{
    0%{
        -webkit-transform:translateY(-100%);
        -ms-transform:translateY(-100%);
        transform:translateY(-100%);
    }
    100%{
        -webkit-transform:translateY(0);
        -ms-transform:translateY(0);
        transform:translateY(0);
    }
}
@-webkit-keyframes fadeIn{
    0%{
        opacity:0;
    }
    100%{
        opacity:1;
    }
}
@keyframes fadeIn{
    0%{
        opacity:0;
    }
    100%{
        opacity:1;
    }
}
.iDuqAg .animation-container-exit{
    -webkit-animation:slideToTop 0.3s 0s,fadeOut 0.3s 0s;
    animation:slideToTop 0.3s 0s,fadeOut 0.3s 0s;
    -webkit-animation-fill-mode:both;
    animation-fill-mode:both;
}
@-webkit-keyframes slideToTop{
    0%{
        -webkit-transform:translateY(0);
        -ms-transform:translateY(0);
        transform:translateY(0);
    }
    100%{
        -webkit-transform:translateY(-100%);
        -ms-transform:translateY(-100%);
        transform:translateY(-100%);
    }
}
@keyframes slideToTop{
    0%{
        -webkit-transform:translateY(0);
        -ms-transform:translateY(0);
        transform:translateY(0);
    }
    100%{
        -webkit-transform:translateY(-100%);
        -ms-transform:translateY(-100%);
        transform:translateY(-100%);
    }
}
@-webkit-keyframes fadeOut{
    0%{
        opacity:1;
    }
    100%{
        opacity:0;
    }
}
@keyframes fadeOut{
    0%{
        opacity:1;
    }
    100%{
        opacity:0;
    }
}
.eiWzKr{
    -webkit-text-decoration:none;
    text-decoration:none;
    color:inherit;
}
.bHvhZH{
    height:100%;
    color:#E60000;
    background:#FFFFFF;
    box-sizing:border-box;
    background-clip:padding-box;
    border-radius:0;
    position:relative;
    box-shadow:none;
}
.bHvhZH [class*=Overlay]{
    border-radius:0;
}
.iWpDmo{
    height:100%;
    position:relative;
    z-index:2;
}
.lonvlc{
    margin:0;
    font-size:16px;
    line-height:20px;
    -webkit-letter-spacing:0;
    -moz-letter-spacing:0;
    -ms-letter-spacing:0;
    letter-spacing:0;
    margin-bottom:12px;
    color:#E60000;
    text-align:center;
}
@media (min-width:768px){
    .lonvlc{
        margin-bottom:12px;
    }
}
@media (min-width:1025px){
    .lonvlc{
        margin-bottom:16px;
    }
}
@media (min-width:1441px){
    .lonvlc{
        margin-bottom:16px;
    }
}
@media (min-width:768px){
    .lonvlc{
        font-size:18px;
        line-height:26px;
        -webkit-letter-spacing:0;
        -moz-letter-spacing:0;
        -ms-letter-spacing:0;
        letter-spacing:0;
    }
}
@media (min-width:1025px){
    .lonvlc{
        font-size:18px;
        line-height:26px;
        -webkit-letter-spacing:0;
        -moz-letter-spacing:0;
        -ms-letter-spacing:0;
        letter-spacing:0;
    }
}
.lonvlc:last-child{
    margin-bottom:0;
}
.kkyblQ{
    margin-top:8px;
}
@media (min-width:768px){
    .kkyblQ{
        margin-top:8px;
    }
}
@media (min-width:1025px){
    .kkyblQ{
        margin-top:8px;
    }
}
@media (min-width:1441px){
    .kkyblQ{
        margin-top:8px;
    }
}
.htdlMJ{
    color:#E60000;
    margin:0;
    font-size:24px;
    line-height:32px;
    -webkit-letter-spacing:0;
    -moz-letter-spacing:0;
    -ms-letter-spacing:0;
    letter-spacing:0;
    margin-bottom:20px;
    font-weight:200;
    text-align:center;
}
@media (min-width:768px){
    .htdlMJ{
        margin-bottom:20px;
    }
}
@media (min-width:1025px){
    .htdlMJ{
        margin-bottom:24px;
    }
}
@media (min-width:1441px){
    .htdlMJ{
        margin-bottom:24px;
    }
}
@media (min-width:768px){
    .htdlMJ{
        font-size:32px;
        line-height:40px;
        -webkit-letter-spacing:0;
        -moz-letter-spacing:0;
        -ms-letter-spacing:0;
        letter-spacing:0;
    }
}
@media (min-width:1025px){
    .htdlMJ{
        font-size:40px;
        line-height:48px;
        -webkit-letter-spacing:0;
        -moz-letter-spacing:0;
        -ms-letter-spacing:0;
        letter-spacing:0;
    }
}
.htdlMJ:last-child{
    margin-bottom:0;
}
.jrWTEh{
    display:none;
}
@supports (display:grid){
    .jrWTEh{
        display:inline-block;
    }
}
@-webkit-keyframes passingWay{
    0%{
        stroke-dashoffset:115;
        stroke-dasharray:0,115;
        stroke-width:0;
    }
    20%{
        stroke-width:4;
    }
    45%{
        stroke-dashoffset:0;
        stroke-dasharray:115,0;
        stroke-width:4;
    }
    55%{
        stroke-dashoffset:0;
        stroke-dasharray:115,0;
        stroke-width:4;
    }
    80%{
        stroke-width:4;
    }
    100%{
        stroke-dashoffset:115;
        stroke-dasharray:0,115;
        stroke-width:0;
    }
}
@keyframes passingWay{
    0%{
        stroke-dashoffset:115;
        stroke-dasharray:0,115;
        stroke-width:0;
    }
    20%{
        stroke-width:4;
    }
    45%{
        stroke-dashoffset:0;
        stroke-dasharray:115,0;
        stroke-width:4;
    }
    55%{
        stroke-dashoffset:0;
        stroke-dasharray:115,0;
        stroke-width:4;
    }
    80%{
        stroke-width:4;
    }
    100%{
        stroke-dashoffset:115;
        stroke-dasharray:0,115;
        stroke-width:0;
    }
}
.jrWTEh svg{
    width:34px;
    height:44px;
}
.jrWTEh svg > path{
    stroke-width:4;
    stroke:#E60000;
    -webkit-animation:passingWay 3s ease infinite both;
    animation:passingWay 3s ease infinite both;
}
.ffxqGh{
    width:100%;
    padding-right:15px;
    padding-left:15px;
    margin-right:auto;
    margin-left:auto;
}
.hwmSmk{
    display:-ms-flexbox;
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-wrap:wrap;
    -webkit-flex-wrap:wrap;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    margin-right:-15px;
    margin-left:-15px;
    -ms-flex-pack:center !important;
    -webkit-box-pack:center !important;
    -webkit-justify-content:center !important;
    -ms-flex-pack:center !important;
    justify-content:center !important;
}
.ljjzyw{
    position:relative;
    width:100%;
    min-height:1px;
    padding-right:15px;
    padding-left:15px;
    -ms-flex:0 0 100%;
    -webkit-flex:0 0 100%;
    -ms-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%;
}
@media (min-width:992px){
    .ljjzyw{
        -ms-flex:0 0 66.66666666666667%;
        -webkit-flex:0 0 66.66666666666667%;
        -ms-flex:0 0 66.66666666666667%;
        flex:0 0 66.66666666666667%;
        max-width:66.66666666666667%;
    }
}
.gmBcgw{
    position:relative;
    width:100%;
    min-height:1px;
    padding-right:15px;
    padding-left:15px;
    -ms-flex:0 0 100%;
    -webkit-flex:0 0 100%;
    -ms-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%;
}
.jQngJy{
    max-width:1264px;
}
.cALTYX{
    margin-right:0;
    margin-left:0;
    margin-top:24px;
    margin-bottom:24px;
}
.cALTYX > [class*='GridColumnstyle__GridColumn']{
    padding-right:0;
    padding-left:0;
}
@media (min-width:768px){
    .cALTYX{
        margin-top:32px;
    }
}
@media (min-width:1025px){
    .cALTYX{
        margin-top:40px;
    }
}
@media (min-width:1441px){
    .cALTYX{
        margin-top:40px;
    }
}
@media (min-width:768px){
    .cALTYX{
        margin-bottom:32px;
    }
}
@media (min-width:1025px){
    .cALTYX{
        margin-bottom:40px;
    }
}
@media (min-width:1441px){
    .cALTYX{
        margin-bottom:40px;
    }
}
.iSMbjI{
    margin-bottom:32px;
}
@media (min-width:768px){
    .iSMbjI{
        margin-bottom:40px;
    }
}
@media (min-width:1025px){
    .iSMbjI{
        margin-bottom:48px;
    }
}
@media (min-width:1441px){
    .iSMbjI{
        margin-bottom:48px;
    }
}
.lfeYSn{
    overflow:hidden;
}
.lfeYSn [class^="Animatestyle"]{
    width:100%;
}
.fEPDKg{
    color:#FFFFFF;
    padding:8px 8px 8px 8px;
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -webkit-justify-content:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-align-items:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-flex-direction:column;
    -ms-flex-direction:column;
    flex-direction:column;
    position:relative;
    background:#00697C;
}
@media (min-width:768px){
    .fEPDKg{
        padding:8px 8px 8px 8px;
    }
}
@media (min-width:1025px){
    .fEPDKg{
        padding:8px 8px 8px 8px;
    }
}
@media (min-width:1441px){
    .fEPDKg{
        padding:8px 8px 8px 8px;
    }
}
.ciGjPu{
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -webkit-justify-content:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-align-items:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-flex-wrap:wrap;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    margin:0;
    font-size:14px;
    line-height:18px;
    -webkit-letter-spacing:0;
    -moz-letter-spacing:0;
    -ms-letter-spacing:0;
    letter-spacing:0;
    margin-bottom:8px;
}
@media (min-width:768px){
    .ciGjPu{
        font-size:14px;
        line-height:18px;
        -webkit-letter-spacing:0;
        -moz-letter-spacing:0;
        -ms-letter-spacing:0;
        letter-spacing:0;
    }
}
@media (min-width:1025px){
    .ciGjPu{
        font-size:14px;
        line-height:18px;
        -webkit-letter-spacing:0;
        -moz-letter-spacing:0;
        -ms-letter-spacing:0;
        letter-spacing:0;
    }
}
.ciGjPu > *{
    margin-right:8px;
}
.ciGjPu:last-child{
    margin-right:0;
}
@media (min-width:768px){
    .ciGjPu{
        margin-bottom:8px;
    }
}
@media (min-width:1025px){
    .ciGjPu{
        margin-bottom:8px;
    }
}
@media (min-width:1441px){
    .ciGjPu{
        margin-bottom:8px;
    }
}
.iRwgJb{
    margin:0;
    font-size:16px;
    line-height:20px;
    -webkit-letter-spacing:0;
    -moz-letter-spacing:0;
    -ms-letter-spacing:0;
    letter-spacing:0;
}
@media (min-width:768px){
    .iRwgJb{
        font-size:18px;
        line-height:26px;
        -webkit-letter-spacing:0;
        -moz-letter-spacing:0;
        -ms-letter-spacing:0;
        letter-spacing:0;
    }
}
@media (min-width:1025px){
    .iRwgJb{
        font-size:18px;
        line-height:26px;
        -webkit-letter-spacing:0;
        -moz-letter-spacing:0;
        -ms-letter-spacing:0;
        letter-spacing:0;
    }
}
.ipBZFX{
    display:-webkit-inline-box;
    display:-webkit-inline-flex;
    display:-ms-inline-flexbox;
    display:inline-flex;
    -webkit-align-items:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    color:#262626;
    color:#F2F2F2;
}
.eXHTIq{
    display:-webkit-inline-box;
    display:-webkit-inline-flex;
    display:-ms-inline-flexbox;
    display:inline-flex;
    -webkit-order:0;
    -ms-flex-order:0;
    order:0;
    margin-right:8px;
}
.kusEO{
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    line-height:initial;
    border-bottom:1px solid transparent;
    font-size:18px;
}
@media (min-width:768px){
    .kusEO{
        line-height:initial;
    }
}
@media (min-width:1025px){
    .kusEO{
        line-height:initial;
    }
}
