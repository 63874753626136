.label {
  color: #005f81;
}

body {
  background: #fff !important;
  font-family: "Muli", sans-serif;
  margin: 0px;
}

textarea,
input,
textarea:focus,
input:focus {
  outline: none;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-nav {
  width: 200px;
  float: left;
}

.App-head {
  background: themePrimary;
}

.App-content {
  float: left;
  margin: 14px;
  margin-top: 20px;
}

.loader {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-radius: 3px;
}

.employeeRow {
  background-color: #e8e8e8 !important;
}

.employeeBlocked {
  background-color: red !important;
  color: white;
}

#nav {
  position: fixed;
  left: 0px;
  top: 0px;
  margin-top: 50px;
  z-index: 1000;
}

#wrapper {
  position: absolute;
  width: 100%;
  left: 200px;
}

label {
  color: #005f81;
}

.mtb5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mlr5 {
  margin-left: 5px;
  margin-right: 5px;
}

.container-thumb-actions {
  margin: 10px;
}

.thumb-basic {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.ed-icon-row {
  padding: 5px;
}

i.ic-medium {
  font-size: 20px;
  color: #3cdc96;
  margin-right: 10px;
  cursor: pointer;
}

i.ic-medium:hover {
  color: #005f81;
}

.ed-sep {
  width: 2px;
  background: #990000;
  margin: 0 auto;
}

.ed-error {
  color: #990000;
  max-height: 200px;
}

.ed-success {
  color: #3cdc96;
  max-height: 200px;
}

.db {
  display: block;
}

.dn {
  display: none;
}

.spacer-default {
  margin-top: 10px;
}

.button-action {
  margin-left: 10px;
}
