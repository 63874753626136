.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ms-Callout-beak {
  background-color: #ffeeed;
  color: #ffeeed;
}

.default-button-edit-screen {
  margin-left: 15px;
}

.deleteButton {
  color: white;
  background-color: #7c0a02;
  float: right;
}

#pr1 {
  display: inline-block;
  margin-right: 10px;
  width: 10px;
}
#pr2 {
  display: inline-block;
  margin-right: 10px;
  width: 10px;
}
#pr3 {
  display: inline-block;
  margin-right: 10px;
  width: 10px;
}
#pr4 {
  display: inline-block;
  margin-right: 10px;
  width: 10px;
}
#pr5 {
  display: inline-block;
  margin-right: 10px;
  width: 10px;
}

.button-for-services {
  margin-left: 5px;
  padding: 5px;
}
.button-for-SUB {
  margin-left: 5px;
}

.App-link {
  color: #61dafb;
}

.outter-lining {
  border-color: gray;
  border-style: solid;
  border-radius: 15px;
  width: 400px;
  height: 280px;
  margin-bottom: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.cards-distance {
  display: "inline-block";
  margin-right: 20;
  margin-bottom: 20;
  width: 320;
}

.outter-lining img {
  margin-top: 10px;
  margin-left: 10px;
}

.product-name {
  margin-top: 5px;
  margin-left: 15px;
  width: 150px;
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  line-height: 40px;

  pointer-events: none;
}
.right-info {
  float: right;
}

.product-info {
  list-style-type: none;
  margin-right: 100px;
  margin-top: 10px;
  margin-bottom: 0;
  padding-left: 15px;
  padding-top: 20px;
}

.changed-by-text {
  margin-left: 10px;
  padding-right: 0;
  margin: 20px;
}

.ms-Checkbox-checkbox {
  background-color: white;
  border-radius: 15px;
}

.ms-Checkbox:hover .ms-Checkbox-checkmark {
  color: white !important;
}

.ms-Checkbox-checkbox .is-checked {
  background-color: #e60000;
}

.ms-Checkbox-checkbox .is-checked.is-disabled {
  background-color: #333333 !important;
}

.ms-Checkbox-checkmark {
  background-color: #e60000;
  border-color: #e60000;
  font-size: 19px !important;
}

.ms-Checkbox.is-disabled .ms-Checkbox-checkmark {
  background-color: #333333 !important;
}

.ms-Button--default {
  background-color: #ba55d3 !important;
  border-radius: 9px !important;
  padding-right: 9px !important;
  padding-left: 25px !important;
  position: absolute !important;
  top: 54px !important;
  left: 25px !important;
}

.ms-Button--default.is-disabled {
  display: none !important;
}

.sub-screen-edit {
  margin-right: 5px;
}

/*
.ms-Button--primary.mandatory-not-checked{
  margin-left: -18px !important;
  padding-left: 15px !important;
  position: absolute !important;
  bottom: 10px !important;
  top: 85px !important;
 
}




.ms-Button--primary {
  background-color: grey;
  border-radius: 9px !important;
  padding-right: 20px !important;
  padding-left: 25px !important;
  position: absolute !important;
  left: 24px !important;
  top: 100px !important;
}

.ms-Button--primary.is-checked{
  background-color: #c3e060 !important;
  border-radius: 9px !important;

} 

.ms-Button--primary.is-disabled{
display: none !important;
} 
*/

.ms-Checkbox-checkbox {
  background: transparent !important;
}

/*
.ms-Button--primary  :hover{
  background-color: green;
  border-color: green;
  border-radius: 9px;
}
*/

.dcicon {
  display: inline-block;

  font-family: FabricMDL2Icons;
  font-style: normal;
  font-weight: 400;
  margin-top: 0;
  font-size: 24px;
  margin-right: 0px;
  padding: 5px;
  color: #e60000;
}

.dcicon:hover {
  background: #eaeaea;
  cursor: pointer;
}

.outter-lining:hover {
  background: #eaeaea;
}

.changed-by-info {
  list-style-type: none;
  padding-left: 0;
  margin-top: 10px;
  margin-right: 80px;
  margin-bottom: 0;
  padding-top: 0;
}

.bottom-text-inner-left {
  float: left;
}
.bottom-text-inner-right {
  float: right;
}

.card {
  margin: 0px 0px 10px 10px;
}

.withPanel {
  padding-right: 600px;
}

.ms-Button--default.is-disabled.deleteNoButton {
  display: initial;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.textDialog {
  max-width: 100%;
  width: 600px;
  max-height: 100%;
  height: 600px;
}

.container-bg {
  background: url(/assets/img/bg.png);
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ep span.digits {
  display: inline-block;
}

.ep span.digits .ld {
  margin: 5px 3px;
}

.ef.container {
  width: 100%;
  max-width: 100%;
}

.et .disable-text-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Firefox */
}
