div#nav {
  height: 100%;
  background: #eaeaea; }

nav {
  width: 200px; }

nav ul[role=list] {
  display: block; }

nav div[role=presentation] {
  margin-top: 30px; }

#nav .wrapper {
  -webkit-box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.28);
  box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.28);
  height: calc(100% - 50px); }

.toggle {
  height: 40px;
  margin: 0px;
  padding: 0px;
  background: #333;
  padding: 3px;
  position: absolute; }

/*  NAV */
.ms-Nav-compositeLink {
  background: transparent; }

/* Icon 2 */
#nav-icon2 {
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: relative;
  margin: 0px;
  transform: scale(0.5, 0.5); }

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 7px;
  width: 50%;
  background: #fff;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0; }

#nav-icon2 span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px; }

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 0px; }

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 14px; }

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 28px; }

#nav-icon2.open span:nth-child(1), #nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

#nav-icon2.open span:nth-child(2), #nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px; }

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px; }

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0; }

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0; }

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 21px; }

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 21px; }
